/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

function openFact(elem, m) {

    /* remove previous Tweet button to replace with the current one */
    $('iframe.twitter-share-button').remove();

    /* update the modal with the content from the selected fact */
    m.setContent(elem.find('.fact-details').html());

    /* set URL hash for bookmarking/sharing */
    var tweetLink = window.location + '?feature_img=' + $('.tingle-modal-box__content .feature-image').attr('src').split('/').pop().split('.')[0] + '#' + elem.attr('data-section-title');
    window.location.hash = elem.attr('data-section-title');

    /* remove tooltip from all but the first occurrence of each term in the selected fact */
    $('body').trigger('factLoaded');
    $('.tingle-modal-box__content .glossary-tooltip').each(function() {
        $('.tingle-modal-box__content span[data-tooltip="' + $(this).attr('data-tooltip') + '"]:not(:first)').contents().unwrap();
    });

    /* create Tweet button specific to this opened fact */
    twttr.widgets.createShareButton(
    //   tweetLink,
      window.location,
      document.getElementsByClassName('tingle-modal-box__footer')[0],
      {
        size: "large",
        text: $('.tingle-modal-box__content h3').text().trim() + ' –', //window.location.hash.substr(1),
        hashtags: "mmt",
        related: "gladkiwi"
    }).then(function (el) {
        $('iframe.twitter-share-button').find('.btn').css('background-color', '#000 !important'); //yeah, this doesn't do shit
    });

    /* open the modal */
    m.open();

    /* animate the SVG once inside the modal */
    var anime_target = '.tingle-modal-box .feature-image path';
    anime({
        targets: anime_target,
        strokeDashoffset: [anime.setDashoffset, 0],
        duration: 3000,
        easing: 'easeInOutQuart',
        loop: false
    });

}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },

    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        
        /* slide red banner down from above viewport / .show() was doing weird things */
        function showAnnouncement() {
            $('#announcement').animate({ marginTop: 0 }, 700);
          }
        /* show red banner 1s after page loads */
        setTimeout(showAnnouncement, 1000);

        /* close red banner */
        $('#announcement .close-btn').on('click', function() {
            var r = $(this).closest('.row');
            r.animate({ marginTop: (r.height()*-1) }, 700, function() { r.hide(); });
        });

        // drawn handdrawn paths
        // var orig = document.querySelector('.handdrawn path'), length, timer;
        // var obj = {length:0,
        //            pathLength:orig.getTotalLength()};
        // TweenMax.to(obj, 1.3, {length:obj.pathLength, onUpdate:drawLine, ease:Linear.easeInOutLinear});
        // function drawLine() {
        //   orig.style.strokeDasharray = [obj.length,obj.pathLength].join(' ');
        // }

      }
    },

    // Facts page
    'facts': {
      init: function() {

        /* instantiate new modal */
        var modal = new tingle.modal({
            footer: true,
            stickyFooter: false,
            closeMethods: ['overlay', 'button', 'escape'],
            closeLabel: "",
            // cssClass: ['custom-class-1', 'custom-class-2'],
            onOpen: function() {
                $('.tingle-modal').animate({ scrollTop: 0 },500);
            },
            onClose: function() {
                /* remove hash without scrolling */
                var scrollV, scrollH, loc = window.location;
                if ("pushState" in history) {
                    history.pushState("", document.title, loc.pathname + loc.search);
                }
                else {
                    // Prevent scrolling by storing the page's current scroll offset
                    scrollV = document.body.scrollTop;
                    scrollH = document.body.scrollLeft;
                    loc.hash = "";

                    // Restore the scroll offset, should be flicker free
                    document.body.scrollTop = scrollV;
                    document.body.scrollLeft = scrollH;
                }
            },
            beforeClose: function() {
                // we need to fade this shit out or something...
                return true;
            }
        });
        // add Close button
        // modal.addFooterBtn('Close', 'tingle-btn tingle-btn--primary', function() {
        //     modal.close();
        // });

        $('.fact').on('mouseenter', function() {
            /* animate SVG drawing when user hovers over a fact box */
            var this_id = $(this).attr('id');
            var anime_target = '#' + this_id + ' .feature-image path';
            anime.remove(anime_target);
            anime({
                targets: anime_target,
                strokeDashoffset: [anime.setDashoffset, 0],
                duration: 2200,
                easing: 'easeInOutQuart',
                loop: false,
                direction: 'alternate'
            });
        });

        /* user clicked on an individual fact box */
        $('.fact').on('click', function() {
            /* go directly to that specific fact modal (for bookmarking and sharing) */
            openFact($(this), modal);
        });

        /* check for hash, because if there is one, we need to go directly to that specific fact modal */
        var win_hash = window.location.hash.substr(1);
        if (win_hash !== '') {  // there is a hash, so go to that modal
            openFact($("[data-section-title='" + win_hash + "']"), modal);
        }

        if (navigator.userAgent.match(/Mobi/)) {
          /* we're on a mobile device, add 'on-mobile' class to affected elements */
          $('.hover-text').addClass('on-mobile');
          $('#fp-nav').addClass('on-mobile');
          $('.fact').addClass('on-mobile');
          $('.factoids').addClass('on-mobile');
          $('h1').addClass('on-mobile');
        }
      }
    },

    // Facts page
    '~OLD facts': {
      init: function() {
        var ratio; // used for nav bar height adjustments in-flight

        $('.pages-wrapper').fullpage({
          scrollingSpeed: 900,
          autoScrolling: true,
          scrollBar: false,
          scrollOverflow: true,
          scrollOverflowReset: false,
          sectionSelector: '.full-page',
          lazyLoading: true,
          navigation: false,
          navigationPosition: 'right',
          bigSectionsDestination: 'top',
          showActiveTooltip: true,

          //events
          onLeave: function(index, nextIndex, direction) {
              /* don't do the white flash thing upon scrolling to the footer */
              // if($('.full-page:eq(' + index + ')').attr('data-anchor') !== 'footer') {
              //     $('#page-overlay').addClass('active');
              //     setTimeout(function(){$('#page-overlay').removeClass('active');}, 800);
              // }

              /* make previous stops have semi-transparent tooltips */
              // $('#fp-nav ul li').removeClass('previous');
              // for (i = 0; i < nextIndex; i++) {
              //     $('#fp-nav ul li:nth-child('+i+')').addClass('previous');
              // }

              /* build the nav progress bar behind the dots */
              // ratio = $(window).height() / $('#fp-nav').height();
              // var bar_height = $('#fp-nav ul li:nth-child('+nextIndex+')').position().top + (44 / Math.min(1,ratio));
              // $('#fp-nav #fp-bar').animate({
              //     height: bar_height
              // }, 1000);

              /* lag note scrolling for interest */
              // if (direction === 'down') {
              //     $('.full-page:eq(' + nextIndex + ')').find('.note').css('top', '+=100vh');
              //     $('.full-page:eq(' + nextIndex + ')').find('.note').each(function(i) {
              //         var n = $(this);
              //         setTimeout(function() {
              //             n.animate({
              //                 top: '-=100vh'
              //             }, 3300);
              //         }, (i+1)*600);
              //     });
              // }

              /* skip notes when scrolling back (upward) */
              if (direction === 'up') {
                  var i = nextIndex-1;
                  if ($('.full-page:eq(' + i + ')').hasClass('factoids') === true) { /* section is a note, scroll to the next section above it */
                      setTimeout(function() { $.fn.fullpage.moveTo(i,1); },0);
                  }
              }

              /* lag note scrolling for interest */
              if (direction === 'down') {
                  var amt = -160;
                  $('.full-page:eq(' + index + ') > div > div').find('.note-text').css('margin-top', amt+'px').animate({
                      marginTop: '0px'
                  },900);
              }
          },
          afterLoad: function(anchorLink, index) {
              // i = index - 1;
              // var el = '.full-page:eq(' + i + ') .feature-image';
              // if ($(el).length !== 0) {
              //     var t = el + ' path';
              //     console.log(t);
              //     anime({
              //         targets: t,
              //         strokeDashoffset: [anime.setDashoffset, 0],
              //         duration: 4200,
              //         easing: 'easeInOutQuart',
              //         loop: false,
              //         begin: function(anim) {
              //             console.log(anim.began);
              //         }
              //     });
              // }
          },
          afterRender: function() {
              /* add the div for the progres bar thing after DOM loads */
              // $('#fp-nav').prepend("<div id='fp-bar'></div>");

              /* scale nav bar to fit if viewport is too small */
              // ratio = $(window).height() / $('#fp-nav').height();
              // if (ratio < 1) { //viewport is smaller than fp-nav, so shrink fp-nav accordingly
              //     $('#fp-nav').css('transform', 'scale('+(ratio*0.95)+')');
              // }
          },
          afterResize: function() {
              // ratio = $(window).height() / $('#fp-nav').height();
              // if (ratio < 1) { //viewport is smaller than fp-nav, so shrink fp-nav accordingly
              //     $('#fp-nav').css('transform', 'scale('+(ratio*0.95)+')');
              // }
          },
          afterResponsive: function(isResponsive) {},
          afterSlideLoad: function(anchorLink, index, slideAnchor, slideIndex) {},
          onSlideLeave: function(anchorLink, index, slideIndex, direction, nextSlideIndex) {}
        });

        /* rotate notes to a random degree (each) for variety */
        // $('.note').each(function() {
        //   var range_r = 4;
        //   var range_p = 60;
        //   var r = Math.random() * (range_r * 2) - range_r;
        //   var p = Math.random() * (range_p * 2) - range_p;
        //   $(this).css({
        //     'transform': 'rotate(' + r + 'deg)',
        //     'left': '+=' + p
        //   });
        // });

        /********* SVG Animations **********/
        anime({
            targets: '.feature-image path',
            strokeDashoffset: [anime.setDashoffset, 0],
            duration: 4200,
            easing: 'easeInOutQuart',
            loop: true,
            direction: 'alternate'
        });

        if (navigator.userAgent.match(/Mobi/)) {
          /* we're on a mobile device, add 'on-mobile' class to affected elements */
          $('.hover-text').addClass('on-mobile');
          $('#fp-nav').addClass('on-mobile');
          $('.fact').addClass('on-mobile');
          $('.factoids').addClass('on-mobile');
          $('h1').addClass('on-mobile');
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
